<template>
<div>
    <b-row class="match-height">
        <b-col cols="4">
            <card-analytic-avg-survey />
        </b-col>
        <b-col cols="8">
            <card-analytic-survey-line-chart />
        </b-col>
        
         <b-col cols="8">
            <card-questionnaire-timeline />
        </b-col>
        <b-col cols="4">
        <card-channel />
        <card-analytic-rewards />
        </b-col>
    </b-row>
</div>
</template>

<script>
import {
    BRow,
    BCol,
} from 'bootstrap-vue'
import CardAnalyticSurveyLineChart from './CardAnalyticSurveyLineChart.vue'
import CardQuestionnaireTimeline from './CardQuestionnaireTimeline.vue'
import CardAnalyticAvgSurvey from './CardAnalyticAvgSurvey.vue'
import CardChannel from '@/views/survey/CardChannel'
import CardAnalyticRewards from '@/views/survey/CardAnalyticRewards'
export default {
    components: {
        BRow,
        BCol,
        CardAnalyticSurveyLineChart,
        CardQuestionnaireTimeline,
        CardAnalyticAvgSurvey,
        CardChannel,
        CardAnalyticRewards
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
