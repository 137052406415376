<template>
  <b-card
    no-body
    class="business-card"
  >
    <b-card-header class="pb-1">
      <b-card-title>Channels</b-card-title>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
        class="cursor-pointer"
      />
    </b-card-header>

    <b-card-body>
      <b-card-text>Summary for all channels for the system use.</b-card-text>
      <h6 class="mb-75">
        Most used Channel WEB
      </h6>
      <div class="business-items">
        <div
          v-for="channel in channelItems"
          :key="channel.option"
          class="business-item"
        >
          <div class="d-flex align-items-center justify-content-between">
            <b-form-checkbox :checked="channel.checked">
              {{ channel.option }}
            </b-form-checkbox>
            <!--
            <b-badge :variant="channel.badgeColor">
              {{ channel.traffic }}
            </b-badge>
            -->
            
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BFormCheckbox, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      channelItems: [
        {
          option: 'SMS', traffic: '12', badgeColor: 'light-success', checked: true,
        },
      ],
    }
  },
}
</script>
