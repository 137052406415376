<template>

    <vue-apex-charts type="radar" height="400" :options="radarChart.chartOptions" :series="radarChart.series" />

</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts,
    },
    data() {
        return {
            radarChart: {
                series: [{
                        name: 'All Messages',
                        data: [],
                    },
                ],
                chartOptions: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        dropShadow: {
                            enabled: false,
                            blur: 8,
                            left: 1,
                            top: 1,
                            opacity: 0.2,
                        },
                    },
                    legend: {
                        show: true,
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    },
                    yaxis: {
                        show: false,
                    },
                    colors: ['#826bf8'],
                    xaxis: {
                        categories: [
                            
                        ],
                    },
                    fill: {
                        opacity: [1, 0.8],
                    },
                    stroke: {
                        show: false,
                        width: 0,
                    },
                    markers: {
                        size: 0,
                    },
                    grid: {
                        show: false,
                    },
                },
            },
        }
    },
}
</script>
