<template>
<b-card>
    <b-row class="pb-50">

        <!-- text and button -->
        <b-col lg="6" order-lg="1" order="2" class="d-flex justify-content-between flex-column">
            <div class="mt-1 mt-lg-0">
                <h2 class="font-weight-bolder mb-25">
                    {{ summary.totalRecipient }}
                </h2>
                <b-card-text class="font-weight-bold mb-2">
                   Total Recipient
                </b-card-text>

            </div>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="shadow" @click="downloadSurvey" >
            <b-spinner small v-show="isLoading" />
                <span id="downloadAction">Download</span>
            </b-button>
        </b-col>

        <!-- dropdown and chart -->
        <b-col lg="6" cols="12" order-lg="2" order="1" class="d-flex justify-content-between flex-column text-right">

            <!-- apex chart -->
            <vue-apex-charts type="bar" height="200" :options="salesBar.chartOptions" :series="salesBar.series" />
        </b-col>
    </b-row>
    <hr>

    <!-- progress bar -->
    <b-row class="avg-sessions pt-50">
        <b-col cols="6" class="mb-2">
            <b-card-text class="mb-50">
                Pending: {{ (summary.totalRecipient - summary.rejected ) - summary.complete }}
            </b-card-text>
            <b-progress :value="(summary.totalRecipient - summary.rejected ) - summary.complete " :max="summary.totalRecipient" height="6px" variant="warning" />
        </b-col>
        <b-col cols="6" class="mb-2">
            <b-card-text class="mb-50">
                Rejected: {{summary.rejected }}
            </b-card-text>
            <b-progress :value="summary.rejected" :max="summary.totalRecipient" height="6px"  />
        </b-col>
        <b-col cols="12">
            <b-card-text class="mb-50">
                Completed: {{ summary.complete }}
            </b-card-text>
            <b-progress :value="summary.complete" :max="summary.totalRecipient" variant="success" height="6px" class="mt-25" />
        </b-col>
    </b-row>
</b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText,
    BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import {
    $themeColors
} from '@themeConfig'
import {
    kFormatter
} from '@core/utils/filter'
import store from '@/store/index'
import $ from 'jquery'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from "moment";
export default {
    components: {
        VueApexCharts,
        BCard,
        BRow,
        BCol,
        BButton,
        BCardText,
        BDropdown,
        BDropdownItem,
        BProgress,
        BSpinner
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            avgData: {},
            salesBar: {},
            summary:{},
            incentive:[],
            isLoading: false,
        }
    },
    created() {
        this.$http.get('/card/card-analytics/avg-sessions').then(res => {
            this.avgData = res.data
        })
    },
    mounted() {
        this.getSurveyChart()
        this.getSurveySummary()
    },
    methods: {
        kFormatter,
        getSurveyChart() {
            let vm = this;
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'survey/v1/view/reponse/graph/' + router.currentRoute.params.id,
                type: "get",
                async: true,
                data: {
                    limit: 20,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    } else {

                        var arrayData = response.data.data.data
                        var series = [];
                        var labels = [];
                        arrayData.forEach((value, index) => {
                            labels.push(value.date)
                            series.push(value.total)
                        });
                        vm.salesBar = {
                            series: [{
                                name: 'Response',
                                data: series,
                            }, ],
                            chartOptions: {
                                chart: {
                                    sparkline: {
                                        enabled: true
                                    },
                                    toolbar: {
                                        show: false
                                    },
                                },
                                grid: {
                                    show: false,
                                    padding: {
                                        left: 0,
                                        right: 0,
                                    },
                                },
                                states: {
                                    hover: {
                                        filter: 'none',
                                    },
                                },
                                colors: [
                                    '#ebf0f7',
                                    '#ebf0f7',
                                    $themeColors.primary,
                                    '#ebf0f7',
                                    '#ebf0f7',
                                    '#ebf0f7',
                                ],
                                plotOptions: {
                                    bar: {
                                        columnWidth: '45%',
                                        distributed: true,
                                        endingShape: 'rounded',
                                    },
                                },
                                tooltip: {
                                    x: {
                                        show: false
                                    },
                                },
                                xaxis: {
                                    type: 'numeric',
                                },
                            },
                        }
                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        },
        getSurveySummary(){
          let vm = this
            console.log("am here testing")
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'survey/v1/view/reponse/summary/'+router.currentRoute.params.id,
                type: "get",
                async: true,
                data: {
                    limit: 20,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                       
                    } else {
                      
                       vm.summary = response.data.data.data[0]

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        },
        downloadSurvey(){
            let vm = this
            vm.isLoading = true
            $('#downloadAction').html(' Please Wait ...');
            $.get({
                url: store.state.rootUrl + 'survey/v1/view/responses/'+router.currentRoute.params.id,
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    export: 1
                },
                success: function (response, status, jQxhr) {
                     $('#downloadAction').html(' Download');
                    if (response.data.code != 200) {
                       vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'error',
                            },
                        })

                    } else {
                       
                        var exportRecipients = [];
                        for (var i = 0; i < response.data.data.data.length; i++) {
                            var item = {};
                            item.surveyName = response.data.data.data[i].surveyName;
                            item.MSISDN = response.data.data.data[i].MSISDN;
                            item.message = response.data.data.data[i].question;
                            item.response = response.data.data.data[i].response;
                            item.created_at = response.data.data.data[i].created_at;
                            exportRecipients.push(item);
                        }
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                        vm.JSONToCSVConvertor(exportRecipients, "SurveyReport_"+exportDate, 1);
                     
                        vm.isLoading = false
                         vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                    }
                },
                error: function (jQxhr, status, error) {
                     $('#downloadAction').html(' Download');
                    vm.isLoading = false
                    var statustext = jQxhr.responseJSON.statusDescription;
                    var errorMessage = ""
                    if (!statustext) {
                        errorMessage = "An error occurred. Try again later.";
                    } else {
                        var messageError = ""
                        messageError = jQxhr.responseJSON.data.message
                        if (!messageError) {
                            errorMessage = statustext;
                        } else {
                            errorMessage = statustext + " " + messageError;
                        }

                    }
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorMessage,
                            icon: 'EditIcon',
                            variant: 'error',
                        },
                    })
                }
            });
       },

       JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "Liden_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                link.remove();
        },
    },
}
</script>
