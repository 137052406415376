<template>
<b-card no-body>
    <b-card-header>
        <b-card-title>Rewards Stats</b-card-title>

    </b-card-header>

    <b-card-body>

        <!-- apex chart -->
        <vue-apex-charts type="radialBar" height="325" :options="productOrdersRadialBar.chartOptions" :series="productOrdersRadialBar.series" />

        <!-- chart info
        <div v-for="(data,key,index) in chartInfo.chartInfo" :key="key" class="d-flex justify-content-between" :class="index === Object.keys(chartInfo.chartInfo).length - 1 ? '':'mb-1'">
            <div class="series-info d-flex align-items-center">
                <feather-icon icon="CircleIcon" size="16" :class="key === 'Airtime' ? 'text-primary': key==='DataBundles'?'text-warning':'text-success'" />
                <span class="font-weight-bold text-capitalize ml-75">{{ key }}</span>
            </div>

            <span>{{ data }}</span>
        </div>
         -->
    </b-card-body>
</b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {
    $themeColors
} from '@themeConfig'
import store from '@/store/index'
import $ from 'jquery'
import router from '@/router'

export default {
    components: {
        VueApexCharts,
        BCard,
        BCardHeader,
        BCardTitle,
        BDropdown,
        BDropdownItem,
        BCardBody,
    },
    data() {
        return {
            chartInfo: {
                chartInfo: {
                    Airtime: 72
                },
            },
            productOrdersRadialBar: {
               
            },
        }
    },
    created() {
        this.$http.get('/card/card-analytics/product-orders').then(res => {
            this.chartInfo = res.data
        })
    },
    mounted() {
        this.getSurveyIncentive()
    },
    methods: {
        getSurveyIncentive() {
            let vm = this;
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'survey/v1/view/incentive/' + router.currentRoute.params.id,
                type: "get",
                async: true,
                data: {
                    limit: 20,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {

                    } else {
                        var arrayData = response.data.data.data
                        var series = [];
                        var labels = [];
                        var totalAmount = 0
                        arrayData.forEach((value, index) => {
                            labels.push(value.reward_name)
                            series.push(value.total)
                            totalAmount =  totalAmount + value.total
                        });
                        vm.productOrdersRadialBar = {
                            series: series,
                            chartOptions: {
                                labels: labels,
                                plotOptions: {
                                    radialBar: {
                                        size: 150,
                                        hollow: {
                                            size: '20%',
                                        },
                                        track: {
                                            strokeWidth: '100%',
                                            margin: 15,
                                        },
                                        dataLabels: {
                                            value: {
                                                fontSize: '1rem',
                                                colors: '#5e5873',
                                                fontWeight: '500',
                                                offsetY: 5,
                                            },
                                            total: {
                                                show: true,
                                                label: 'Total',
                                                fontSize: '1.286rem',
                                                colors: '#5e5873',
                                                fontWeight: '500',

                                                formatter() {
                                                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                                    return totalAmount
                                                },
                                            },
                                        },
                                    },
                                },
                                colors: [$themeColors.primary, $themeColors.warning, $themeColors.success],
                                stroke: {
                                    lineCap: 'round',
                                },
                                chart: {
                                    height: 355,
                                    dropShadow: {
                                        enabled: true,
                                        blur: 3,
                                        left: 1,
                                        top: 1,
                                        opacity: 0.1,
                                    },
                                },
                            },
                        }

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        }
    }
}
</script>
