<template>
<div>
    <span v-for="(item, index) in optionReponses" :key="index++">
        <b-card-text class="mb-0">
            <small>{{item.answer}} - ({{item.totalResponse}}) {{((item.totalResponse / max) * 100).toFixed(2)}}%</small>
        </b-card-text>
        <b-progress v-model="item.totalResponse" variant="success" :label="`${((item.totalResponse / max) * 100).toFixed(2)}%`" :max="max" />

    </span>
    <vue-apex-charts type="donut" height="350" :options="donutChart.chartOptions" :series="donutChart.series" v-if="data.question_typeId == 1" />
</div>
</template>

<script>
import {
    BCard,
    BCardText,
    BProgress
} from 'bootstrap-vue'
import store from '@/store/index'
import $ from 'jquery'
import VueApexCharts from 'vue-apexcharts'
export default {
    components: {
        BCard,
        BProgress,
        BCardText,
        VueApexCharts

    },
    props: {
        data: {
            app_id: Number,
            question_id: Number,
            name: String,
            question_typeId: Number,
            question: String,
            status: String,
            created_at: String,
            total_count: Number
        },
    },
    data() {
        return {
            isLoading: false,
            optionReponses: [],
            answer: {
                one: 40,
                two: 20,
                three: 15,
                four: 25
            },
            questionType:"",
            max: 100,
            yesNo: {
                yes: 40,
                no: 60
            },
            donutChart: {
                series: [],
                chartOptions: {
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    },
                    colors: [
                        '#ffe700',
                        
                        '#00d4bd',
                        '#826bf8',
                        '#2b9bf4',
                        '#FFA1A1',
                        '#ff2447',
                        '#dc3545',
                        '#ffc107',
                        '#dc3545',
                         '#fd7e14',
                        '#e83e8c',

                    ],
                    dataLabels: {
                        enabled: true,
                        formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}%`
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        fontSize: '2rem',
                                        fontFamily: 'Montserrat',
                                    },
                                    value: {
                                        fontSize: '1rem',
                                        fontFamily: 'Montserrat',
                                        formatter(val) {
                                            // eslint-disable-next-line radix
                                            return `${parseInt(val)}%`
                                        },
                                    },
                                    total: {
                                        show: true,
                                        fontSize: '1.5rem',
                                        label: '',
                                        formatter(val) {
                                            return `${parseInt(val)}%`
                                        },
                                    },
                                },
                            },
                        },
                    },
                    labels: [],
                    responsive: [{
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 380,
                                },
                                legend: {
                                    position: 'bottom',
                                },
                            },
                        },
                        {
                            breakpoint: 576,
                            options: {
                                chart: {
                                    height: 320,
                                },
                                plotOptions: {
                                    pie: {
                                        donut: {
                                            labels: {
                                                show: true,
                                                name: {
                                                    fontSize: '1.5rem',
                                                },
                                                value: {
                                                    fontSize: '1rem',
                                                },
                                                total: {
                                                    fontSize: '1.5rem',
                                                },
                                            },
                                        },
                                    },
                                },
                                legend: {
                                    show: true,
                                },
                            },
                        },
                    ],
                },
            },

        }
    },
    mounted() {
        this.getOptionsReponses()
    },
    methods: {
        getOptionsReponses() {
            let vm = this;
            this.questionType = this.data.question_typeId;
            console.log("am here testing")
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'survey/v1/view/survey/response/option/' + this.data.question_id,
                type: "get",
                async: true,
                data: {
                    limit: 20,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                       
                    } else {
                        var arrayData = response.data.data.data
                        var ratingArray = [];
                        var ratingLabelArray = [];
                        var bestCount = 0
                        var bestName = ""
                        var totalReponse = 0;
                        arrayData.forEach((value, index) => {
                            ratingArray.push(value.totalResponse)
                            ratingLabelArray.push(value.answer)
                            totalReponse = totalReponse + Number(value.totalResponse)
                            if (value.totalResponse > bestCount) {
                                bestCount = value.totalResponse;
                                bestName = value.answer
                            }
                        });
                        vm.max = totalReponse
                        vm.optionReponses = response.data.data.data
                        var bestPercent = (Number(bestCount) / totalReponse) * 100
                        vm.donutChart = {
                            series: ratingArray,
                            chartOptions: {
                                legend: {
                                    show: true,
                                    position: 'bottom',
                                    fontSize: '14px',
                                    fontFamily: 'Montserrat',
                                },
                                colors: [
                                    '#ffe700',
                                    '#00d4bd'

                                ],
                                dataLabels: {
                                    enabled: true,
                                    formatter(val) {
                                        // eslint-disable-next-line radix
                                        return `${parseInt(val)}%`
                                    },
                                },
                                plotOptions: {
                                    pie: {
                                        donut: {
                                            labels: {
                                                show: true,
                                                name: {
                                                    fontSize: '2rem',
                                                    fontFamily: 'Montserrat',
                                                },
                                                value: {
                                                    fontSize: '1rem',
                                                    fontFamily: 'Montserrat',
                                                    formatter(val) {
                                                        // eslint-disable-next-line radix
                                                        return `${parseInt(val*100)}%`
                                                    },
                                                },
                                                total: {
                                                    show: true,
                                                    fontSize: '1.5rem',
                                                    label: "Best Rate " + bestName,
                                                    formatter(val) {
                                                        return bestPercent + '%'
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                                labels: ratingLabelArray,
                                responsive: [{
                                        breakpoint: 992,
                                        options: {
                                            chart: {
                                                height: 380,
                                            },
                                            legend: {
                                                position: 'bottom',
                                            },
                                        },
                                    },
                                    {
                                        breakpoint: 576,
                                        options: {
                                            chart: {
                                                height: 320,
                                            },
                                            plotOptions: {
                                                pie: {
                                                    donut: {
                                                        labels: {
                                                            show: true,
                                                            name: {
                                                                fontSize: '1.5rem',
                                                            },
                                                            value: {
                                                                fontSize: '1rem',
                                                            },
                                                            total: {
                                                                fontSize: '1.5rem',
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                            legend: {
                                                show: true,
                                            },
                                        },
                                    },
                                ],
                            }
                        }

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        }
    }
}
</script>
