<template>
<b-card no-body>
    <b-card-header>
        <div class="d-flex align-items-center">
            <feather-icon size="24" class="mr-1" icon="ListIcon" />
            <b-card-title class="ml-25">
                Questionnaire Stats
            </b-card-title>
        </div>
        <feather-icon icon="MoreVerticalIcon" size="18" />
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
        <app-timeline>
            <app-timeline-item variant="primary" v-for="(item, index) in questions" :key="index++">
                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6>Question {{index++}} {{item.name}}</h6>
                    <small class="timeline-item-time text-nowrap text-muted ml-1">Created {{item.created_at}}</small>
                </div>
                <p>{{item.question}} ? </p>
                <card-reponse-option-stats :data="item" />
                 <ClosedEndStats v-if="item.question_typeId == 11" />
            </app-timeline-item>
           
        </app-timeline>
        <!--/ timeline -->
    </b-card-body>
</b-card>
</template>

<script>
import {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BImg,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    VBTooltip,
    BProgress,
    BCardText
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VueApexCharts from 'vue-apexcharts'
import ClosedEndStats from '@/views/survey/ClosedEndStats'
import CardReponseOptionStats from './CardReponseOptionStats.vue'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'
import router from '@/router'
/* eslint-disable global-require */
export default {
    components: {
        BCard,
        BImg,
        BCardBody,
        BCardHeader,
        BCardTitle,
        AppTimeline,
        AppTimelineItem,
        BMedia,
        BAvatar,
        BMediaBody,
        BMediaAside,
        BAvatarGroup,
        BProgress,
        BCardText,
        VueApexCharts,
        ClosedEndStats,
        CardReponseOptionStats

    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
         
            donutChart: {
                series: [4, 6, 10, 11, 27, 30, 4, 3, 1, 4],
                chartOptions: {
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    },
                    colors: [
                        '#ffe700',
                        
                        '#00d4bd',
                        '#826bf8',
                        '#2b9bf4',
                        '#FFA1A1',
                        '#ff2447',
                        '#dc3545',
                        '#ffc107',
                        '#dc3545',
                         '#fd7e14',
                        '#e83e8c',
                       
                        
                    ],
                    dataLabels: {
                        enabled: true,
                        formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}%`
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        fontSize: '2rem',
                                        fontFamily: 'Montserrat',
                                    },
                                    value: {
                                        fontSize: '1rem',
                                        fontFamily: 'Montserrat',
                                        formatter(val) {
                                            // eslint-disable-next-line radix
                                            return `${parseInt(val)}%`
                                        },
                                    },
                                    total: {
                                        show: true,
                                        fontSize: '1.5rem',
                                        label: 'Best Rate 6',
                                        formatter() {
                                            return '30%'
                                        },
                                    },
                                },
                            },
                        },
                    },
                    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    responsive: [{
                            breakpoint: 992,
                            options: {
                                chart: {
                                    height: 380,
                                },
                                legend: {
                                    position: 'bottom',
                                },
                            },
                        },
                        {
                            breakpoint: 576,
                            options: {
                                chart: {
                                    height: 320,
                                },
                                plotOptions: {
                                    pie: {
                                        donut: {
                                            labels: {
                                                show: true,
                                                name: {
                                                    fontSize: '1.5rem',
                                                },
                                                value: {
                                                    fontSize: '1rem',
                                                },
                                                total: {
                                                    fontSize: '1.5rem',
                                                },
                                            },
                                        },
                                    },
                                },
                                legend: {
                                    show: true,
                                },
                            },
                        },
                    ],
                },
            },
            avatars: [{
                    userImg: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
                    name: 'Billy Hopkins'
                },
                {
                    userImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
                    name: 'Amy Carson'
                },
                {
                    userImg: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
                    name: 'Brandon Miles'
                },
                {
                    userImg: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
                    name: 'Daisy Weber'
                },
                {
                    userImg: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
                    name: 'Jenny Looper'
                },
            ],
            max: 100,
            yesNo: {
                yes: 40,
                no:60
            },
            answer: {
                one: 40,
                two: 20,
                three: 15,
                four: 25
            },
            questions:[],
            isLoading: false
        }
    },
    mounted() {
        this.getSurveyQuestion()
    },
    methods:{
         getSurveyQuestion(){
            let vm  = this;
            console.log("am here testing")
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'survey/v1/view/survey/questions/'+router.currentRoute.params.id,
                type: "get",
                async: true,
                data: {
                    limit: 20,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    } else {

                       vm.questions = response.data.data.data

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        }
    }
}
</script>
