<template>
<b-card no-body>
    <b-card-header class="align-items-baseline">
        <div>
            <b-card-title class="mb-25">
                Response
            </b-card-title>
            <b-card-text class="mb-0">
            </b-card-text>
        </div>
        <feather-icon icon="SettingsIcon" size="18" class="text-muted cursor-pointer" />
    </b-card-header>

    <b-card-body class="pb-0">

        <!-- apex chart -->
        <vue-apex-charts type="line" height="240" :options="salesLine.chartOptions" :series="salesLine.series" />
    </b-card-body>
</b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {
    $themeColors
} from '@themeConfig'
import store from '@/store/index'
import $ from 'jquery'
import router from '@/router'

export default {
    components: {
        VueApexCharts,
        BCard,
        BCardHeader,
        BCardText,
        BCardTitle,
        BCardBody,
    },
    data() {
        return {
            salesLine: null
        }
    },
    mounted() {
        this.getSurveyChart()
    },
    methods: {
        getSurveyChart() {
            let vm = this;
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'survey/v1/view/reponse/graph/' + router.currentRoute.params.id,
                type: "get",
                async: true,
                data: {
                    limit: 20,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                       
                    } else {

                        var arrayData = response.data.data.data
                        var series = [];
                        var labels = [];
                        arrayData.forEach((value, index) => {
                            labels.push(value.date)
                            series.push(value.total)
                        });
                        vm.salesLine = {
                            series: [{
                                name: 'Response',
                                data: series,
                            }, ],
                            chartOptions: {
                                chart: {
                                    toolbar: {
                                        show: false
                                    },
                                    zoom: {
                                        enabled: false
                                    },
                                    type: 'line',
                                    dropShadow: {
                                        enabled: true,
                                        top: 18,
                                        left: 2,
                                        blur: 5,
                                        opacity: 0.2,
                                    },
                                    offsetX: -10,
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 4,
                                },
                                grid: {
                                    borderColor: '#ebe9f1',
                                    padding: {
                                        top: -20,
                                        bottom: 5,
                                        left: 20,
                                    },
                                },
                                legend: {
                                    show: false,
                                },
                                colors: ['#df87f2'],
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        shade: 'dark',
                                        inverseColors: false,
                                        gradientToColors: [$themeColors.primary],
                                        shadeIntensity: 1,
                                        type: 'horizontal',
                                        opacityFrom: 1,
                                        opacityTo: 1,
                                        stops: [0, 100, 100, 100],
                                    },
                                },
                                markers: {
                                    size: 0,
                                    hover: {
                                        size: 5,
                                    },
                                },
                                xaxis: {
                                    labels: {
                                        offsetY: 5,
                                        style: {
                                            colors: '#b9b9c3',
                                            fontSize: '0.857rem',
                                        },
                                    },
                                    axisTicks: {
                                        show: false,
                                    },
                                    categories: labels,
                                    axisBorder: {
                                        show: false,
                                    },
                                    tickPlacement: 'on',
                                },
                                yaxis: {
                                    tickAmount: 5,
                                    labels: {
                                        style: {
                                            colors: '#b9b9c3',
                                            fontSize: '0.857rem',
                                        },
                                    },
                                },
                                tooltip: {
                                    x: {
                                        show: false
                                    },
                                },
                            },
                        }
                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        }
    }

}
</script>
